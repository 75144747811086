<template>
	<div class="position-relative">
		<div class="d-flex justify-content-between mb-3">
			<div class="mb-2">
				<h2 class="playfairDisplay mb-0 text-primary" v-text="title"></h2>
			</div>
			<div class="mb-2">
				<button type="button" v-on:click="getProductosTipos()" title="Actualizar" class="btn btn-outline-primary me-2" :disabled="loadingTipos">
					<font-awesome-icon :icon="faRedo" />
				</button>
				<router-link :to="{name: 'admin.productos.tipos.create'}" class="btn btn-primary">
					<font-awesome-icon :icon="faPlus" /> Agregar tipo
				</router-link>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div v-if="(loadingTipos && !paginate) || productosTiposFiltered.length<=0">
					<div v-if="(loadingTipos && !paginate)" class="text-muted text-center py-5">
						<div class="spinner-grow" role="status" style="width: 3rem; height: 3rem;">
							<span class="visually-hidden">Loading...</span>
						</div>
						<h2>
							Cargando...
						</h2>
					</div>
					<div v-else class="py-3">
						<div class="text-center mx-auto bg-light rounded-circle p-5" style="width: 200px; height: 200px;">
							<img :src="SVGEmpty" class="img-fluid">
						</div>
						<div class="text-center">
							<p>
								No se encontraron registros
							</p>
							<router-link :to="{name: 'admin.productos.tipos.create'}" class="btn btn-primary">
								<font-awesome-icon :icon="faPlus" /> Agregar tipo
							</router-link>
						</div>
					</div>
				</div>
				<div class="table-responsive" v-else>
					<table class="table table-hover">
						<thead>
							<th width="60">Código</th>
							<th>Nombre</th>
							<th width="150" class="d-none d-lg-table-cell d-xl-table-cell">Fecha de actualización</th>
							<th width="120"></th>
						</thead>
						<tbody>
							<tr v-for="(tipo, index) in productosTiposFiltered" :key="index" class="cursor-pointer">
								<th>{{tipo.CODIGO_PRODUCTO_TIPO}}</th>
								<td>
									<span v-if="tipo.adjuntos && tipo.adjuntos.length>0" class="me-2 img-thumbnail">
										<img :src="tipo.adjuntos[0].ARCHIVO" width="25" height="25" class="rounded-circle" style="object-fit: contain; object-position: center;">
									</span>
									{{tipo.DESCRIPCION_TIPO}}
								</td>
								<td class="text-center d-none d-lg-table-cell d-xl-table-cell">
									<span class="badge bg-dark">
										{{$filters.dateTime(tipo.FECHA_ACTUALIZACION)}}
									</span>
								</td>
								<td align="right">
									<div class="table-options">
										<router-link :to="{name: 'admin.productos.tipos.edit', params: {id: tipo.CODIGO_PRODUCTO_TIPO}}" class="btn btn-link me-1">
											<font-awesome-icon :icon="faEdit" />
										</router-link>

										<DeleteButton class="btn btn-link" :codigo="tipo.CODIGO_PRODUCTO_TIPO" url="deleteProductosTipos">
											<font-awesome-icon :icon="faTrash" />
										</DeleteButton>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<router-view />
		<infinite-scroll
			@infinite-scroll="infiniteHandler"
			:noResult="noResult"
			>
		</infinite-scroll>
		<div v-if="noResult && productosTiposFiltered.length>0 && !loadingTipos" class="text-center text-muted">
			No hay más resultado
		</div>
	</div>
</template>
<script type="text/javascript">
import InfiniteScroll from 'infinite-loading-vue3'
import SVGEmpty from '@/assets/img/icons/no_data.svg'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DeleteButton from '@/components/DeleteButton.vue'
import { faPlus, faFilter, faEdit, faTrash, faRedo } from '@fortawesome/free-solid-svg-icons'
export default {
	components: {
		FontAwesomeIcon,
		InfiniteScroll,
		DeleteButton
	},
	data(){
		return {
			faPlus,
			faFilter,
			faEdit,
			faTrash,
			faRedo,
			SVGEmpty,
			loadingTipos: false,
			noResult: false,
			paginate: false
		}
	},
	mounted(){
		this.getProductosTipos()
	},
	methods: {
		infiniteHandler(){
			if (!this.noResult && !this.loadingTipos) {
				this.paginate = true
				this.getProductosTipos()
			}
		},
		getProductosTipos(){
			this.loadingTipos = true
			var filters = {}
			if (this.paginate) {
				filters.url = this.productosTipos.next_page_url
			}
			this.$store.dispatch('getProductosTipos', filters).then((res) => {
				if(res.data.data.length > 0 && this.productosTipos.next_page_url != null){
					this.noResult = false
				}else{
					this.noResult = true
				}
				return res
			}).catch(err => {
				return err
			}).then(() => {
				this.loadingTipos = false
				this.paginate = false
			})
		},
	},
	computed: {
		productosTipos() {
			return this.$store.getters.productosTipos || {}
		},
		productosTiposFiltered() {
			return this.productosTipos.data || []
		},
		title(){
			return this.$route.meta.title ? this.$route.meta.title : ''
		}
	}
}
</script>